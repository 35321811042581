import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import FeatureBlock from '../../../components/FeatureBlock';
import Container from '../../../components/UI/Container';
import Particles from '../Particle';
import Link from 'next/link';
import BannerWrapper, {
  DiscountLabel,
  BannerObject,
} from './bannerSection.style';

import BannerObject1 from '../../../assets/image/saas/banner/bannerObject1.png';
import BannerObject2 from '../../../assets/image/saas/banner/GitAssit-Home-Page_image.png';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
  btnMore,
  btnBeta,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <AnchorLink href={btnBeta.link} offset={btnBeta.offset}>
        <Button title="Join the beta" {...btnStyle} />
      </AnchorLink>
      <AnchorLink href={btnMore.link} offset={btnMore.offset}>
        <Button
          className="outlined"
          title="EXPLORE MORE"
          variant="outlined"
          {...outlineBtnStyle} />
      </AnchorLink>
    </Fragment>
  );
  return (
    <BannerWrapper id="banner_section">
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <DiscountLabel>
            <Text content="An amazing product of  " {...discountText} />
              <Link href={discountAmount.url}>
                <a><Text content="UnicornPowered" {...discountAmount} /></a>
              </Link>
            </DiscountLabel>
            <FeatureBlock
              title={
                <Heading
                  content="The smartest way to manage your dev team"
                  {...title}
                />
              }
              description={
                <Text
                  content="GitAssist helps you to keep the focus on the right thing by giving you insights and automatic reminders. Arouse commitment and increase efficiency by gamification. "
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      <BannerObject>
        <div className="objectWrapper">
          <Image src={BannerObject1} alt="BannerObject1" />
          <div className="dashboardWrapper">
            <Image src={BannerObject2} alt="BannerObject2" />
          </div>
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '55px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#5167db',
    ml: '18px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#eb4d4b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
    url: 'https://unicornpowered.com',
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500,
  },
  btnMore: {
    link: '#features_section',
    offset: '70',
  },
  btnBeta: {
    link: '#join_beta',
    offset: '70',
  },
};

export default BannerSection;
