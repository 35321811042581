import Client1 from '../../assets/image/saas/testimonial/client-1.jpg';
import Client2 from '../../assets/image/agency/client/denny.png';

export const Faq = [
  {
    id: 1,
    title: 'Who we are?',
    description:
      'A dev team based in the beautiful Quebec City. We are experienced with teams of all size and we work to improve efficiency by removing the useless cognitive charge and improve the engagement by gamification.',
  },
  {
    id: 2,
    expend: true,
    title: 'Can I have a place in the Beta test?',
    description:
      'The places are limited in the beta test. We want to keep a high level of feedback with our beta testers. But, if we can\'t give you a place in our beta, we will give you a discount on your first-year subscription.  ',
  },
];

export const Features = [
  {
    id: 1,
    icon: 'flaticon-atom',
    title: 'Data-Driven',
    description:
      'Give the mean full information to take right decisions and give better feedback ',
  },
  {
    id: 2,
    icon: 'flaticon-conversation',
    title: 'People-First',
    description:
      'Put human first in your team and engage every employee, empower every manager ',
  },
  {
    id: 3,
    icon: 'flaticon-creative',
    title: 'Removing cognitive charge',
    description:
      'AI generate automatic insights and reminders to help teams focus on most important things base on your Git data ',
  },
];

export const Footer_Data = [
  {
    title: 'About Us',
    menuItems: [
      {
        url: 'https://unicornpowered.com',
        text: 'About Us',
      },
      {
        url: 'https://unicornpowered.com',
        text: 'Copyright',
      },
      {
        url: '#',
        text: 'Support Center',
      },
      {
        url: '#',
        text: 'Customer Support',
      },
    ],
  },
  {
    title: 'Our Information',
    menuItems: [
      {
        url: '#',
        text: 'Privacy Policy',
      },
      {
        url: '#',
        text: 'Terms & Conditions',
      },
    ],
  },
  {
    title: 'Policy',
    menuItems: [
      {
        url: '#',
        text: 'Application security',
      },
      {
        url: '#',
        text: 'Software principles',
      },
      {
        url: '#',
        text: 'Unwanted software policy',
      },
      {
        url: '#',
        text: 'Responsible supply chain',
      },
    ],
  },
];

export const Service = [
  {
    id: 1,
    icon: 'flaticon-briefing',
    title: 'Dashboard',
    description:
      'Dashboard to track most important KPI like Review duration, Reviews pending or Reviews completed not merged ',
  },
  {
    id: 2,
    icon: 'flaticon-conversation',
    title: 'Reminders',
    description:
      'Review and merge pull requests faster with Slack reminders and notifications ',
  },
  {
    id: 3,
    icon: 'flaticon-creative',
    title: 'Insights',
    description:
      'Remove bottlenecks and optimize process using insights from your Git data ',
  },
  {
    id: 4,
    icon: 'flaticon-startup',
    title: 'Continuous Improvement',
    description:
      'Set team goals and track results. Follow-up in retros and one-on-ones using our reports ',
  },
  {
    id: 5,
    icon: 'flaticon-annonymous',
    title: 'Anonymous Survey',
    description:
      'The anonymous weekly survey gives you the vibe of your team and the team health ',
  },
  {
    id: 6,
    icon: 'flaticon-trophy',
    title: 'Gamification',
    description:
      'Gamification helps you reward best practices or objectives completion, manage better feedback and improve efficiency ',
  },
];

export const Timeline = [
  {
    title: 'Connect your process',
    description:
      'Use integrations plugins to connect your development process and start to collect valuable data from GitHub, GitLab, Bitbucket, JIRA and more .',
  },
  {
    title: 'Customize',
    description:
      'Choose the best practices you want to reward in your team and adjust the importance .',
  },
  {
    title: 'Gamification and data analysis',
    description:
      'Gamification helps you reward best practices or met objectives, manage better feedback and improve efficiency .',
  },
  {
    title: 'Insights and Reminders',
    description:
      'Receive automatic insights and reminders to help teams focus on most important things .',
  },
];

export const Testimonial = [
  {
    id: 1,
    name: 'Michal Andry',
    designation: 'Ceo of Invission Co.',
    comment:
      'Love to work with this designer in every our future project to ensure we are going to build best prototyping features.',
    avatar_url: Client1,
    social_icon: 'flaticon-instagram',
  },
  {
    id: 2,
    name: 'Roman Ul Oman',
    designation: 'Co-founder of QatarDiaries',
    comment:
      'Impressed with master class support of the team and really look forward for the future. A true passionate team.',
    avatar_url: Client2,
    social_icon: 'flaticon-twitter-logo-silhouette',
  },
];

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '70',
  },
  {
    label: 'Feature',
    path: '#features_section',
    offset: '70',
  },
  {
    label: 'How it works',
    path: '#how_it_works',
    offset: '70',
  },
  {
    label: 'Guidelines',
    path: '#guidelines_section',
    offset: '70',
  },
  {
    label: 'Pricing',
    path: '#pricing_section',
    offset: '70',
  },
  {
    label: 'FAQ',
    path: '#faq_section',
    offset: '70',
  },
  {
    label: 'Join the beta',
    path: '#join_beta',
    offset: '70',
  },
];

export const MONTHLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Small Team',
    description: 'Perfect for small team and managers with a single team ',
    price: '$0',
    priceLabel: 'Limit of 1 team and 1 project',
    buttonLabel: 'NOT AVAILABLE YET',
    url: '#',
    listItems: [
      {
        content: 'Dashboard',
      },
      {
        content: 'Reminders notifications',
      },
      {
        content: 'Insights notifications',
      },
      {
        content: 'Continuous Improvement ',
      },
      {
        content: 'Anonymous Survey',
      },
      {
        content: 'Gamification',
      },
    ],
  },
  {
    name: 'Multiple teams',
    description: 'Ideal for managers with multiple teams ',
    price: '$15 CAD',
    priceLabel: 'Per employee, per month ',
    buttonLabel: 'Join the beta',
    url: 'https://forms.gle/aVwdzHXCfSNZ5CM79',
    listItems: [
      {
        content: 'Dashboard',
      },
      {
        content: 'Reminders notifications',
      },
      {
        content: 'Insights notifications',
      },
      {
        content: 'Continuous Improvement ',
      },
      {
        content: 'Anonymous Survey',
      },
      {
        content: 'Gamification',
      },
      {
        content: 'Unlimited teams and projects',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'A tailored experience for large organizations ',
    price: 'Custom',
    priceLabel: ' ',
    buttonLabel: 'Contact us',
    url: 'https://forms.gle/aVwdzHXCfSNZ5CM79',
    listItems: [
      {
        content: 'Everythings in Multiple teams and...',
      },
      {
        content: 'Accompanied Onboarding & Rollout Support ',
      },
      {
        content: 'Dedicated Senior Account Manager',
      },
      {
        content: 'Advanced User Provisioning ',
      },
    ],
  },
];

export const YEARLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Small Team',
    description: 'Perfect for small team and managers with a single team ',
    price: '$0',
    priceLabel: 'Limit of 1 team and 1 project',
    buttonLabel: 'NOT AVAILABLE YET',
    url: '#',
    listItems: [
      {
        content: 'Dashboard',
      },
      {
        content: 'Reminders',
      },
      {
        content: 'Insights',
      },
      {
        content: 'Continuous Improvement ',
      },
      {
        content: 'Anonymous Survey',
      },
      {
        content: 'Gamification',
      },
    ],
  },
  {
    name: 'Multiple teams',
    description: 'Ideal for managers with multiple teams ',
    price: '$12.50 CAD',
    priceLabel: 'Per employee, per month with subscription yearly ',
    buttonLabel: 'Join the beta',
    url: 'https://forms.gle/aVwdzHXCfSNZ5CM79',
    listItems: [
      {
        content: 'Dashboard',
      },
      {
        content: 'Reminders',
      },
      {
        content: 'Insights',
      },
      {
        content: 'Continuous Improvement ',
      },
      {
        content: 'Anonymous Survey',
      },
      {
        content: 'Gamification',
      },
      {
        content: 'Unlimited teams and projects',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'A tailored experience for large organizations ',
    price: 'Custom',
    priceLabel: ' ',
    buttonLabel: 'Contact us',
    url: 'https://forms.gle/aVwdzHXCfSNZ5CM79',
    listItems: [
      {
        content: 'Everythings in Multiple teams and...',
      },
      {
        content: 'Accompanied Onboarding & Rollout Support ',
      },
      {
        content: 'Dedicated Senior Account Manager',
      },
      {
        content: 'Advanced User Provisioning ',
      },
    ],
  },
];
